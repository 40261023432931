import Header from './Common/Header';
import Footer from './Common/Footer'
import Dashboarduserdetails from './Dashboarduserdetails/Dashboarduserdetails';
import Userplansdetails from './Userplansdetails/Userplansdetails';
import Plandetails from './Plansdetails/Plansdetails';

const Dashboard = () => {
  return (
        <>
         <Header />
         <Dashboarduserdetails />
         <Userplansdetails />
         <Plandetails />
        <Footer /> 
        </>
    
  )
}

export default Dashboard;