export const loginRequest = () => {
    return{
        type:"Login_Request",
    }
} 

export const loginSucess = (user) => {
    return{
        type:"Login_Sucess",
        payload : {user}
    }
}

export const loginFailure = (error) => {
    return {
        type:"Login_failure",
        payload : {error}
    }
}