import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'

import store from './Store';
import { Provider } from "react-redux";
import Login from './signin/login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Transfer from './Transfer';


function App() {
  return (
    <Provider store ={store}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/transfer" element={<Transfer /> }></Route> 
          </Routes>
      </Router>
    </Provider>
  );
}

export default App;
