const initialState = {
    popVisible: true,
    popImg: null,
    popMessage : ''

}

export const Reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'Transfer_sucess':
            return {
                ...state,
                popMessage : "Transfer Sucessfull",
                popImg : action.payload.sucessImage,
                popVisible : !state.popVisible
                
            };
        case 'Transfer_Failed':
            return {
                ...state,
                popMessage : "Tranfer Failed",
                popImg : action.payload.failureImage,
                popVisible : !state.popVisible
            };
            default:
            return state;
    }

}