import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Headeruserdropdown = ({isVisible}) => {
    const navigate = useNavigate();
    
    const handleLogout = () => {
        navigate('/')
    }

    return(
        
        
        
        <>
            {isVisible && <div className="user-dropdown">
                <ul>
                    <li><Link to="#">Update Details</Link></li>
                    <li><Link to="#">Diagnostic Tool</Link></li>
                    <li><Link to="#">Installer Tool</Link></li>
                    <li><button onClick={handleLogout}>Logout</button></li>
                </ul>
            </div>}
        </>
    )
}

export default Headeruserdropdown;