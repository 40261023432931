import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import logo from "../Common/logo1.png";
import leftimg from "../webindia-left-img.png";
import "./login.css";
import { useState } from "react";
import { loginRequest, loginFailure, loginSucess } from "../AuthAction";
import userData from "./Userdetails.json";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorMessage = useSelector((state) => state.auth.error);

  const handleUserName = (event) => {
    const { value } = event.target;
    setUserName(value);
    setIsVisible(value && password);
  };
  const handlePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
    setIsVisible(userName && value);
  };
  const handleLogin = () => {
    dispatch(loginRequest());

    const userLog = userData.find(
      (user) =>
        (user.email === userName || user.mobileNumber === userName || user.subscriberID === userName)  &&
        user.password === password
    );

    if (userLog) {
      dispatch(loginSucess(userLog));
      navigate("/dashboard");
    } else {
      dispatch(loginFailure("Invalid username or password"));
    }
  };

  return (
    <div className="login-sec">
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className="login-left-img">
              <img src={leftimg} className="img-fluid" />
            </div>
          </Col>
          <Col md={6}>
            <div className="login-from-block">
              <div className="login-logo">
                <img src={logo} />
              </div>
              <span>Sign In</span>
              <p>
                Welcome back to Digicrews Sign in now to continue exploring you
                connect details and progress with updated data
              </p>
              <div className="login-form">
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                <div className="input-box">
                  <div className="input-label login-label">
                    <span>Mobile Number, Email or Subscriber ID</span>
                  </div>
                  <div className="input-block">
                    <input
                      type="Phone"
                      placeholder="Enter email, or mobile number, or ID"
                      onChange={handleUserName}
                    ></input>
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label login-label">
                    <span>Password</span>
                  </div>
                  <div className="input-block">
                    <input
                      type="Password"
                      placeholder="Enter your Password"
                      onChange={handlePassword}
                    ></input>
                  </div>
                </div>
                <Button
                  variant="primary"
                  disabled={!isVisible}
                  onClick={handleLogin}
                  className="login-btn"
                >
                  Login
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
