import { Button, Col, Container, Row } from "react-bootstrap";
import Useraccountdetails from "../Useraccountdetails/Useraccountdetails";
import "./Usersubscription.css";
import Subscription from "../Plansdetails/Subscription";
import arrowright from "../Plansdetails/arrowright.svg"
import Calender from "../Calenderdata/Calender";


const Usersubscription = () => {
 

  return (
    <div className="user-active-account">
      <Container>
        <Row>
          <Col md={7}>
            <h2>Account Details</h2>
            <Useraccountdetails />
          </Col>
          <Col md={5}>
            <h2>Subscription Details</h2>
            <div className="active-user-box">
              <h4>SUBSCRIPTION TYPE</h4>
              <span>Auto Renew</span>
              <p>Lorem ipsum. Description about auto renew plan.</p>
              <Button variant="primary" className="btn-automated">
                Change to manual billing
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
            <Col md={12}>
                <div className="data-table-block">
                    <div className="heading-block">
                        <div className="head-title">
                            <h5>Recent Payment History</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and</p>
                        </div>
                    </div>
                     <Calender />
                </div>
            </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="user-plan-full">
                <h5>Upgrade Plan</h5>
                <p>FREE upgrade to the next higher plan when you buy 180/350 days plans</p>
              <div className="free-plan-sec">
                <Row>
                  <Col md={3}>
                    <div className="free-plan-box">
                      <h5>50 Mbps</h5>
                      <span>UNLI 180 / 365</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Upgrade to Advantage
                        </li>
                        <li>
                          <img src={arrowright} />
                          Up to 50 Mbps
                        </li>
                      <Button variant="primary" className="btn-upgrade">FREE UPGRADE</Button>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="free-plan-box">
                      <h5>100 Mbps</h5>
                      <span>ADVANTAGE  180 / 365</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Upgrade to Elite
                        </li>
                        <li>
                          <img src={arrowright} />
                          Up to 100 Mbps
                        </li>
                        <Button variant="primary" className="btn-upgrade">CHOOSE PLAN</Button>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="free-plan-box">
                      <h5>200 Mbps</h5>
                      <span>ELITE  180 / 365</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Upgrade to Prestige
                        </li>
                        <li>
                          <img src={arrowright} />
                          Up to 200 Mbps
                        </li>
                        <Button variant="primary" className="btn-upgrade">CHOOSE PLAN</Button>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="free-plan-box">
                      <h5>300 Mbps</h5>
                      <span>PRESTIGE  180 / 365</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Upgrade to <strong>NEW</strong> Prestige
                        </li>
                        <li>
                          <img src={arrowright} />
                          Up to 300 Mbps
                        </li>
                        <Button variant="primary" className="btn-upgrade">CHOOSE PLAN</Button>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="user-subs">
              <Subscription />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Usersubscription;
