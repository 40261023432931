const authState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

export const AuthReducer = (State = authState, action) => {
  switch (action.type) {
    case "Login_Request":
      return {
        ...State,
        loading: true,
        error: null,
      };
    case "Login_Sucess":
      return {
        ...State,
        loading: false,
        user: action.payload.user,
        isAuthenticated: true,
        
      };
    case "Login_failure":
      return {
        ...State,
        loading: false,
        error: action.payload.error,
      };
      default:
        return State;
  }
};
