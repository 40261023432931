import { Col, Container, Row } from "react-bootstrap";
import arrowright from "./arrowright.svg";
import "./Plandetails.css";
import Subscription from "./Subscription";

const Plandetails = () => {
  return (
    <div className="plans-block">
      <Container>
        <Row>
          <Col md={7}>
            <Subscription />
          </Col>
          <Col md={5}>
            <div className="free-plan-block">
              <h5>Free Upgrade</h5>
              <p>to the next higher plan when you buy 180/350 days plans</p>
              <div className="free-plan-sec">
                <Row>
                  <Col md={6}>
                    <div className="free-plan-box">
                      <h5>180 Mbps</h5>
                      <span>UNLI 180</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Advantage 180
                        </li>
                        <li>
                          <img src={arrowright} />
                          Elite 180
                        </li>
                        <li>
                          <img src={arrowright} />
                          Prestige 180
                        </li>
                        <li>
                          <img src={arrowright} />
                          Free Upgrade to 300 MBPS
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="free-plan-box">
                      <h5>360 Mbps</h5>
                      <span>UNLI 365</span>
                      <ul>
                        <li>
                          <img src={arrowright} />
                          Advantage 365
                        </li>
                        <li>
                          <img src={arrowright} />
                          Elite 365
                        </li>
                        <li>
                          <img src={arrowright} />
                          Prestige 365
                        </li>
                        <li>
                          <img src={arrowright} />
                          Free Upgrade to 300 MBPS
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Plandetails;
