import logo from "./logo1.png";
import { Nav, Navbar, NavDropdown, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Common.css";
import Paymentpop from "../Paymentpop/Paymentpop";
import Headeruserdetails from "../Headeruserdetails/Headeruserdetails";
const Header = () => {
  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Row>
            <Col md={3}>
              <div className="logo-block">
                <Navbar.Brand href="#home" className="logo">
                  <img src={logo} alt="logo" />
                </Navbar.Brand>
              </div>
            </Col>
            <Col md={6}>
              <div className="menu-block">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="m-auto menu-links">
                    <Nav.Item>
                      {" "}
                      <Link to="/dashboard" className="nav-link">
                        Dashboard
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      {" "}
                      <Link to="/transfer" className="nav-link">Transfer</Link>
                    </Nav.Item>
                    <Paymentpop></Paymentpop>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Col>
            <Col md={3}>
              <div className="head-user-detail">
                <Headeruserdetails></Headeruserdetails>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
