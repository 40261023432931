import { Button, Col, Container, Row } from "react-bootstrap"
import './Userplansdetails.css'
import Useraccountdetails from "../Useraccountdetails/Useraccountdetails";
const Userplansdetails = () => {
    return(
        <div className="user-plans-blocks">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="page-title">
                            <h2>Dashboard</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div className="user-plan-sec">
                            <p>Current Package Plan</p>
                            <h4>₱240.00</h4>
                            <Button variant="primary">Buy P500/30Days Unli Wi-Fi</Button>
                        </div>
                    </Col>
                    <Col md={7}>
                        <Useraccountdetails />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Userplansdetails;