import balanceicon from './balance-icon.svg'
import activity from './activity-icon.svg'
import clocksand from './sand-clock-icon.svg'
import subsid from './subsid-icon.svg'
import { useSelector } from 'react-redux'
const Useraccountdetails = () => {

    const user = useSelector((state) => state.auth.user);
    return(
        <div className="user-account">
            <div className="user-account-block">
                <div className="account-box">
                    <div className="account-icon-box">
                        <img src={balanceicon} />
                    </div>
                    <div className='account-cont-box'>
                        <h4>Account Balance</h4>
                        <span>₱0.00</span>
                    </div>
                </div>
                <div className="account-box">
                    <div className="account-icon-box">
                        <img src={activity} />
                    </div>
                    <div className='account-cont-box'>
                        <h4>Active Connection</h4>
                        <span>None</span>
                    </div>
                </div>
            </div>
            <div className="user-account-block">
                <div className="account-box">
                    <div className="account-icon-box">
                        <img src={subsid} />
                    </div>
                    <div className='account-cont-box'>
                        <h4>Subscriber ID</h4>
                        <span>{user ? user.subscriberID : '0000000'}</span>
                    </div>
                </div>
                <div className="account-box">
                    <div className="account-icon-box">
                        <img src={clocksand} />
                    </div>
                    <div className='account-cont-box'>
                        <h4>Pending Activation</h4>
                        <span>You have 1 pending subscription.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Useraccountdetails;