import React, { useState, useEffect, useMemo } from "react";
import { useTable } from "react-table";
import userdatas from "./UserData.json";
import "./Calender.css";
import MultiDatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Button } from "react-bootstrap";
import calendericon from "../Usersubscription/calender-icon.svg";

const Calender = () => {
  const [data, setData] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    setData(userdatas);
  }, []);

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Date", accessor: "date" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Current Balance", accessor: "currentBalance" },
      { Header: "Status Payment", accessor: "statusPayment" },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handleDateSelection = (dates) => {
    setSelectedDates(dates);

    if (dates.length === 0) {
      setData(userdatas);
    } else {
      const filteredData = userdatas.filter((item) =>
        dates.some((date) => date.format("YYYY-MM-DD") === item.date)
      );
      setData(filteredData);
    }
  };

  return (
    <>
    <div className="button-block-datepicker">
      <Button
        variant="secondary"
        className="payment-btn"
        onClick={() => setShowDatePicker(true)}
      >
        <img src={calendericon} alt="Calendar Icon" /> Select Payment Dates
      </Button>
      {showDatePicker && (
        <div className="date-picker-wrapper">
          <MultiDatePicker className="date-picker-input"
            multiple
            plugins={[<DatePanel />]}
            value={selectedDates}
            onChange={handleDateSelection}
            format="YYYY-MM-DD"
          />
        </div>
      )}
      </div>
      <div className="data-table-box">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((columns) => (
                  <th {...columns.getHeaderProps()}>
                    {columns.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="data-table-body">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Calender;
