import DashUserImg from './userdetail-img.png'
import './Dashboarduserdetails.css'
import {  Row, Col, Container } from 'react-bootstrap'
import mailicon from './mail-svg.svg'
import phoneicon from './callcalling-svg.svg'
import locationicon from './location-svg.svg'
import { useSelector } from 'react-redux'
const Dashboarduserdetails = () => {

    const user = useSelector((state) => state.auth.user);

    return(
        <div className="dashboard-user-details">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="dashboard-top">
                            <div className="user-image-block">
                                    <img src= { DashUserImg } alt="userimage" />
                            </div>
                            <div className="user-dash-cont-block">
                                <div className="dash-user-name">
                                    <h4>{user ? user.name : 'UserName'} <span>{user ? user.subscriberID : 'subscriberID'}</span></h4>
                                </div>
                                <div className="dash-contact-details">
                                    <ul>
                                        <li><img src={ mailicon } /> <span>{user ? user.email : 'usermail@gmail.com'}</span></li>
                                        <li><img src={ phoneicon } /> <span>{user ? user.mobileNumber : '0123456789'}</span></li>
                                        <li><img src={ locationicon } /> <span>{user ? user.address : 'Area, District, State, Country'}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
           </Container>
        </div>
    )
}

export default Dashboarduserdetails;