import notify from "./notificationbing.svg";
import userImage from "./Ellipse 308.png";
import Headeruserdropdown from "./Headeruserdropdown";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Headeruserdetails.css";
import { useState } from "react";
import { useSelector } from "react-redux";

const Headeruserdetails = () => {
  const [isVisible, setisVisible] = useState(false);
  const handleToogle = () => {
    setisVisible(!isVisible);
  };

  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="notification-icon">
        <img src={notify} alt="notification-icon" />
      </div>
      <div className="profile-block">
        <Button onClick={handleToogle}>
          <img src={userImage} alt="user-image" />
          <span>{user ? user.name : "Username"}</span>
          <FontAwesomeIcon icon={faAngleDown} />
        </Button>
        <Headeruserdropdown isVisible={isVisible} />
      </div>
    </>
  );
};

export default Headeruserdetails;
