import Footer from "./Common/Footer"
import Header from "./Common/Header"
import Dashboarduserdetails from "./Dashboarduserdetails/Dashboarduserdetails";
import Usersubscription from "./Usersubscription/Usersubscription";

const Transfer = () => {
    return(
       <>
       <Header />
        <Dashboarduserdetails />
        <Usersubscription />
       <Footer />
       </>
    )
};

export default Transfer;