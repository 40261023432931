export const transferSucess = (sucessImage) => {
    return{
        type:"Transfer_sucess",
        payload : {sucessImage},
    }
};
export const transferFailure = (failureImage) => {
    return{
        type:"Transfer_Failed",
        payload : {failureImage},
    }
}

