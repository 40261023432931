import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
import {transferSucess, transferFailure} from '../Action'
import {  Row, Col, Button, Modal } from 'react-bootstrap'
import './Paymentpop.css'
import successImage from '../tick-circle.svg'
import failureImage from '../failure-circle.svg';

const Paymentpop = () => {
    const [show, setShow] = useState(false)
    const [SubscriberID, setSubscriberID] = useState('')
    const [amount, setAmount] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [popVisible, setPopVisible] = useState(false)

    const dispatch = useDispatch();
    const state = useSelector(state => state.data)

    const transferSucessHandle = () => {
      dispatch(transferSucess(successImage));
      setShow(false); // Hide the payment modal
      setPopVisible(true); // Show the success message popup
    }
    const transferFailureHandle = () => {
      dispatch(transferFailure(failureImage)); // Dispatch failure action
      setShow(false); // Hide the payment modal
      setPopVisible(true); // Show the failure message popup
    }

   

    const popupShow = () => setShow(true)

    const popupClose = () => {
      setSubscriberID('');
      setAmount('');
      setIsVisible(false);
      setShow(false);
      setPopVisible(false); // Hide the success message popup
  };
    

    const handleSubscriberID = (event) => {
      const {value} = event.target;
      setSubscriberID(value);
      setIsVisible( value && amount )
    }
    
    const handleAmount = (event) => {
      const {value} = event.target;
      setAmount(value);
      setIsVisible( SubscriberID && value )
    }


  return (
    <>
      <Button variant="primary" className="btn-payment"  onClick={popupShow}>Payment</Button>
      <Modal show={show} onHide={popupClose} className="paymen-pop" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
              <Col md={6}>
                  <div className="input-label">
                      <span>Subscriber ID or mobile number</span>
                  </div>
              </Col>
              <Col md={6}>
                  <div className="input-block">
                      <input type="number"  placeholder='e.g. 1000123456 or 09178139805' onChange={handleSubscriberID}></input>
                  </div>
              </Col>
              </Row>
              <Row>
              <Col md={6}>
                  <div className="input-label">
                      <span>Amount</span>
                  </div>
              </Col>
              <Col md={6}>
                  <div className="input-block">
                      <input type="number" placeholder='Enter amount' onChange={handleAmount}></input>
                  </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="policy-cont">
                  <p>By clicking <strong>Transfer</strong>, you're affirming your choice to move your balance from this account to the one filled out above. Are you certain you wish to continue?</p>
                </div>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={transferFailureHandle}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!isVisible} onClick={transferSucessHandle}>
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={popVisible} onHide={popupClose} className="paymen-pop payment-pop-sucess" animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
              <Col md={12}>
                  <div className="trans-cont-msg">
                    <img src={state.popImg} alt="sucessimage"/>
                    <p>{state.popMessage}</p>
                  </div>
              </Col>
              </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button  onClick={popupClose} varient="primary">close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Paymentpop;