import React, { useState } from "react";
import { Button } from "react-bootstrap";

const Subscription = () => {
  const [activeTab, setActiveTab] = useState(0); // Initially set the first tab as active

  const handleTabClick = (index) => {
    setActiveTab(index); // Update the active tab when clicked
  };

  return (
    <div className="subscription-block">
      <h5>Subscription Plans</h5>
      <div className="subs-tab-sec">
        <div className="tabs">
          <div
            className={activeTab === 0 ? "tab active-tab" : "tab"}
            onClick={() => handleTabClick(0)}
          >
            FREE
          </div>
          <div
            className={activeTab === 1 ? "tab active-tab" : "tab"}
            onClick={() => handleTabClick(1)}
          >
            UNLI WOOFY
          </div>
          <div
            className={activeTab === 2 ? "tab active-tab" : "tab"}
            onClick={() => handleTabClick(2)}
          >
            UNLI WOOFY ADVANTAGE
          </div>
          <div
            className={activeTab === 3 ? "tab active-tab" : "tab"}
            onClick={() => handleTabClick(3)}
          >
            UNLI WOOFY ELITE
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 0 && 
          <div className="subs-box-block">
          <div className="subs-box">
            <h5>Free 1 Months</h5>
            <h4>₱100.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Free 3 Months</h5>
            <h4>₱300.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Free 5 Months</h5>
            <h4>₱500.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Free 8 Months</h5>
            <h4>₱800.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        </div>}

          {activeTab === 1 && <div className="subs-box-block">
          <div className="subs-box">
            <h5>Unli Woofy 7</h5>
            <h4>₱100.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Unli Woofy 30</h5>
            <h4>₱500.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Unli Woofy 180</h5>
            <h4>₱700.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Unli Woofy 360</h5>
            <h4>₱1000.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        </div>}
          {activeTab === 2 && <div className="subs-box-block">
          <div className="subs-box">
            <h5>WOOFY ADV 120</h5>
            <h4>₱100.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy ADV 220</h5>
            <h4>₱500.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy ADV 350</h5>
            <h4>₱700.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy ADV 500</h5>
            <h4>₱1000.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        </div>}
          {activeTab === 3 && <div className="subs-box-block">
          <div className="subs-box">
            <h5>Woofy Elite 1</h5>
            <h4>₱100.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy Elite 2</h5>
            <h4>₱500.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy Elite 3</h5>
            <h4>₱700.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        <div className="subs-box">
            <h5>Woofy Elite 4</h5>
            <h4>₱1000.00</h4>
            <p>Free installation and activation</p>
            <span>Free 7 days Trail with high speed</span>
            <Button variant="primary" className="subs-btn">Buy Now</Button>
        </div>
        </div>}
        </div>
      </div>
    </div>
  );
};
export default Subscription;
