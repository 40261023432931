import { createStore, combineReducers } from "redux";
import { Reducer } from "./Reducer";
import { AuthReducer } from "./AuthReducer";


const listReducer = combineReducers({
    data : Reducer,
    auth : AuthReducer, 
});

const store = createStore(listReducer);

export default store;